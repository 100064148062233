<template>
	<div class="p-commit-all">
		<div class="p-commit-head">
			<div class="p-commit-left">
				订单评价
			</div>
		</div>
		<div class="mt19">
			<div class="p-commit-item" v-for="(item,index) in tcommit" :key="index">
				<div class="p-c-item-l">
					<img :src="item.goods_img" />
					<div>{{item.goods_name}}</div>
				</div>
				<!-- 没头绪 -->
				<div class="p-c-item-r">
					<div class="p-c-item-r-h">
						<div>课程评分</div>
						<img class="p-star" v-for="(items,indexa) in item.star" :key="indexa" @click="choose(items.fen,items,index,item.goods_id)"
						 :src="items.img" />
						<div class="p-number">({{item.rank}}分)</div>
					</div>
					<div class="p-commit-t">
						<div>课程评价</div>
						<textarea v-model="item.content" class="p-commit-o"></textarea>
					</div>
				</div>
			</div>
		</div>
		<div class="p-commit-bottom" @click="tocommit()">
			<div>
				发布评价
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				commitdata: [],
				tcommit: [],
				click: 1,
				choos: 1,
				aboutlist: [],
				star: [{
						fen: 1,
						img: require("../../../assets/img/star/none.png"),
						ischoose: 0
					},
					{
						fen: 2,
						img: require("../../../assets/img/star/none.png")
					},
					{
						fen: 3,
						img: require("../../../assets/img/star/none.png")
					},
					{
						fen: 4,
						img: require("../../../assets/img/star/none.png")
					},
					{
						fen: 5,
						img: require("../../../assets/img/star/none.png")
					}
				],
			};
		},
		created() {
			this.getcommit(this.$route.query.id)
		},
		watch: {
			'$route': function() {
				this.getcommit(this.$route.query.id)
			}
		},
		methods: {
			choose(e, item, index, id) {
				this.tcommit[index].rank = e
				if (e == 1) {
					this.star = [{
							fen: 1,
							img: require("../../../assets/img/star/one.png"),
							ischoose: 0
						},
						{
							fen: 2,
							img: require("../../../assets/img/star/none.png")
						},
						{
							fen: 3,
							img: require("../../../assets/img/star/none.png")
						},
						{
							fen: 4,
							img: require("../../../assets/img/star/none.png")
						},
						{
							fen: 5,
							img: require("../../../assets/img/star/none.png")
						}
					]
					this.tcommit[index].star = this.star
					return
				}
				if (e == 2) {
					this.star = [{
							fen: 1,
							img: require("../../../assets/img/star/one.png"),
							ischoose: 0
						},
						{
							fen: 2,
							img: require("../../../assets/img/star/one.png")
						},
						{
							fen: 3,
							img: require("../../../assets/img/star/none.png")
						},
						{
							fen: 4,
							img: require("../../../assets/img/star/none.png")
						},
						{
							fen: 5,
							img: require("../../../assets/img/star/none.png")
						}
					]
					this.tcommit[index].star = this.star
					return
				}
				if (e == 3) {
					this.star = [{
							fen: 1,
							img: require("../../../assets/img/star/one.png"),
							ischoose: 0
						},
						{
							fen: 2,
							img: require("../../../assets/img/star/one.png")
						},
						{
							fen: 3,
							img: require("../../../assets/img/star/one.png")
						},
						{
							fen: 4,
							img: require("../../../assets/img/star/none.png")
						},
						{
							fen: 5,
							img: require("../../../assets/img/star/none.png")
						}
					]
					this.tcommit[index].star = this.star
					return
				}
				if (e == 4) {
					this.star = [{
							fen: 1,
							img: require("../../../assets/img/star/one.png"),
							ischoose: 0
						},
						{
							fen: 2,
							img: require("../../../assets/img/star/one.png")
						},
						{
							fen: 3,
							img: require("../../../assets/img/star/one.png")
						},
						{
							fen: 4,
							img: require("../../../assets/img/star/one.png")
						},
						{
							fen: 5,
							img: require("../../../assets/img/star/none.png")
						}
					]
					this.tcommit[index].star = this.star
					return
				}
				if (e == 5) {
					this.star = [{
							fen: 1,
							img: require("../../../assets/img/star/one.png"),
							ischoose: 0
						},
						{
							fen: 2,
							img: require("../../../assets/img/star/one.png")
						},
						{
							fen: 3,
							img: require("../../../assets/img/star/one.png")
						},
						{
							fen: 4,
							img: require("../../../assets/img/star/one.png")
						},
						{
							fen: 5,
							img: require("../../../assets/img/star/one.png")
						}
					]
					this.tcommit[index].star = this.star
					return
				}

			},
			getcommit(e) {
				this.$post("user/commentindex", {
					order_id: e
				}).then(response => {
					if (response.error == 0) {
						this.commitdata = response.data
						for (var i = 0; i < response.data.order_goods.length; i++) {
							var project = {
								goods_id: response.data.order_goods[i].goods_id,
								content: "",
								rank: 0,
								goods_img: response.data.order_goods[i].goods_img,
								goods_name: response.data.order_goods[i].goods_name,
								star: this.star
							}
							this.tcommit.push(project)
						}
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			tocommit() {
				this.$post("user/comment", {
					order_id: this.$route.query.id,
					param: this.tcommit
				}).then(response => {
					if (response.error == 0) {
						this.$message.success(response.msg)
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			}
		}
	};
</script>


<style>
	.p-commit-all {
		width: 80%;
		margin: 0 auto;
	}

	.p-commit-head {
		width: 100%;
		height: 54px;
		position: relative;
		top: 64px;
		margin-bottom: 83px;
		background: #FFFFFF;
	}

	.p-commit-left {
		width: 150px;
		height: 54px;
		background: #00D0B0;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		text-align: center;
		color: #FFFFFF;
		line-height: 54px;

	}

	.mt19 {
		margin-top: 19px;
		width: 100%;
		height: auto;
	}

	.p-commit-item {
		width: 100%;
		height: 290px;
		background: #FFFFFF;
		box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.06);
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.p-c-item-l {
		width: 396px;
		border-right: 1px solid #F2F0F0;
	}

	.p-c-item-l img {
		margin-top: 41px;
		margin-left: 59px;
		width: 279px;
		height: 146px;
	}

	.p-c-item-l div {
		margin-top: 19px;
		margin-left: 59px;
		margin-right: 63px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 24px;

	}

	.p-c-item-r {
		margin-left: 58px;
		margin-top: 42px;
		color: #333333;
		font-size: 14px;
	}

	.p-c-item-r-h {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.p-star {
		width: 29px;
		height: 28px;
		margin-left: 27px;
	}

	.p-number {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FC7517;
		margin-left: 17px;
		margin-top: 6px;
	}

	.p-commit-t {
		width: 100%;
		height: 154px;
		margin-top: 25px;
		display: flex;
		line-height: 154px;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.p-commit-o {
		width: 494px;
		height: 154px;
		font-size: 14px;
		background: #F3F3F3;
		margin-left: 7px;
	}

	textarea {
		border: none;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
	}

	textarea:focus {
		outline: none !important;
	}

	.p-commit-bottom {
		width: 100%;
		height: 98px;
		background: #FFFFFF;
		box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.06);
		margin-top: 20px;
		display: flex;
		flex-direction: row-reverse;
	}

	.p-commit-bottom div {
		width: 168px;
		height: 54px;
		background: #00D0B0;
		line-height: 54px;
		text-align: center;
		margin: auto;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
	}
</style>
